import { DEFAULT_LOCALE } from '@front/shared';
import { FooterOneColumn, RichText, toImageProps } from '@front/shared/ds';
import { addJurisdictionSupport } from '@front/shared/utils';
import { Footer as TFooter, Locale, Jurisdiction } from '@shared/master-types';
import { useRouter } from 'next/router';
import React from 'react';

import { useActions } from '../../../features/actions/useActions.hook';

export type TFooterOneColumnConnectedConnectedProps = Extract<
  Required<TFooter>['footer'][number],
  { blockType: 'footer-one-column' }
> & {
  locales?: Locale[];
  jurisdictionList?: Jurisdiction[];
  jurisdictionItem?: Jurisdiction;
};

const FooterOneColumnConnected: React.FC<
  TFooterOneColumnConnectedConnectedProps
> = props => {
  const {
    hasLine,
    locales = [],
    description,
    callToAction,
    docs = [],
    socials = {},
    contacts,
    downloads,
    locations,
    jurisdictionList,
    jurisdictionItem,
  } = props;

  const router = useRouter();

  const currentLocale = String(router.query.locale || DEFAULT_LOCALE);

  const docsActions = addJurisdictionSupport({
    actionList: useActions(docs),
    jurisdictionList: jurisdictionList,
    localeList: locales,
    slug: jurisdictionItem?.slug,
  });

  const ctaActions = addJurisdictionSupport({
    actionList: useActions(callToAction?.actions),
    jurisdictionList: jurisdictionList,
    localeList: locales,
    slug: jurisdictionItem?.slug,
  });

  return (
    <FooterOneColumn
      hasLine={hasLine}
      currentLocale={currentLocale}
      locales={locales.map(l => ({
        value: l.locale,
        label: l.label,
        country: l.country,
      }))}
      contacts={contacts?.map(contact => ({
        label: contact.label,
        type: contact.type,
        value: contact.email || contact.telephone || contact.textRow,
      }))}
      downloads={{
        appStore: {
          qrCode: toImageProps(downloads?.appStore?.qrCode),
          url: downloads?.appStore?.url,
        },
        googlePlay: {
          qrCode: toImageProps(downloads?.googlePlay?.qrCode),
          url: downloads?.googlePlay?.url,
        },
      }}
      locations={locations?.map(location => ({
        ...location,
        label: location.label || '',
        url: location.url || '',
      }))}
      docs={docsActions}
      socials={socials}
      callToAction={{
        title: callToAction?.title,
        description: callToAction?.description,
        actions: ctaActions,
      }}
      description={<RichText content={description} />}
    />
  );
};

export default FooterOneColumnConnected;
